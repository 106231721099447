








// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import NavComponent from "../components/Nav.vue";
import CardComponent from "../components/GameCard.vue";

@Component({
  components: {
    NavComponent,
    CardComponent,
  },
})
export default class GamesView extends Vue {}
