




















// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CardComponent extends Vue {
  message(): void {
    console.log("Button click routes to game");
  }
  data() {
    return {
      gameList: [
        {
          title: "Pinball",
          text: "Play Pinball in a 3d setting! Beat your friends' high scores!",
          route: "/pinball",
          src:
            "https://superawesomevectors.com/wp-content/uploads/2015/12/game-controller-icon.jpg",
        },
        // {
        //   title: "Planet Defender",
        //   text: "This is the card for game two, put a description and image here",
        //   route: "/planet-defender",
        //   src:
        //     "https://superawesomevectors.com/wp-content/uploads/2015/12/game-controller-icon.jpg",
        // }
      ],
    };
  }
}
